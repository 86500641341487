.wrapper-psys {
  margin: auto;
  max-width: 1300px;
  width: 90%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 920px) {
  .wrapper-psys {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    width: fit-content;
  }
}
