.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 15px;
  width: 100%;
}
.grid-wrapper-child {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  width: 100%;
}
.one {
  grid-column: 1;
  grid-row: 1;
}
.two {
  grid-column: 2;
  grid-row: 1;
}
.three {
  grid-column: 3;
  grid-row: 1;
}
.four {
  grid-column: 4;
  grid-row: 1;
}
.five {
  grid-column: 5;
  grid-row: 1;
}
.six {
  grid-column: 6;
  grid-row: 1;
}
.seven {
  grid-column: 7;
  grid-row: 1;
}
.grey {
  background-color: rgba(255, 171, 35, 0.3);
  border-radius: 5px;
  height: 4px;
}
.orange {
  height: 4px;
  border-radius: 5px;
  background-color: #ffab23;
}
