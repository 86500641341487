.input {
  background-color: rgb(171, 164, 240, 0.3);
  border-width: 0px 0px 2px 0px;
  border-color: #572caf;
  padding: 10px 0px 10px 10px;
  transition: 0.3;
  width: 100%;
  max-width: 110px;
  margin: 10px 0px;
  margin-right: 10px;
  font-weight: bold;
  font-family: "Medium";
}
.input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: #572caf;
  opacity: 1;
  font-size: 15px;
}
input:focus {
  outline: none !important;
  border: 2px solid #572caf;
}
.large {
  width: 100%;
  max-width: 380px;
}
.center {
  text-align: center;
  margin: auto;
}
.check-wrapper {
  font-weight: bold;
  margin: 14px 0px;
}
.check-wrapper input {
  display: none;
}
.check-wrapper label {
  background-color: rgb(171, 164, 240, 0.3);
  padding: 10px 10px 10px 10px;
  transition: 0.3;
  margin: 10px 0px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
.checked {
  color: #572caf;
  opacity: 1;
  font-weight: bold;
  font-size: 15px;
  border: 2px #572caf solid;
}
.to-check {
  color: #572caf;
  opacity: 1;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 2px #572caf solid;
}
.select {
  background-color: rgb(171, 164, 240, 0.3);
  border-width: 0px 0px 2px 0px;
  border-color: #572caf;
  padding: 7px 0px 7px 10px;
  transition: 0.3;
  width: 100%;
  max-width: 130px;
  margin: 10px 0px;
  font-family: "Medium";
  font-weight: bold;
  color: #572caf;
  font-size: 15px;
}

.select-large {
  background-color: rgb(171, 164, 240, 0.3);
  border-width: 0px 0px 2px 0px;
  border-color: #572caf;
  padding: 7px 0px 7px 10px;
  transition: 0.3;
  width: 450px;
  margin: 10px 0px;
  font-family: "Medium";
  font-weight: bold;
  color: #572caf;
  font-size: 15px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media screen and (max-width: 920px) {
  .auto {
    margin: 0px auto;
  }
  .none {
    display: none;
  }
  .input {
    background-color: rgb(171, 164, 240, 0.3);
    border-width: 0px 0px 2px 0px;
    border-color: #572caf;
    padding: 10px 0px 10px 10px;
    transition: 0.3;
    width: 300px;
    max-width: 300px;
    margin: 10px 0px;
  }
  .large {
    width: 100%;
    min-width: 300px;
  }
  .check-wrapper label {
    padding: 10px 7px 10px 7px;
    margin-right: -2px;
    margin-left: -2px;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .large {
    width: 100%;
    max-width: 300px;
  }
  .input::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: #572caf;
    opacity: 1;
    font-size: 15px;
  }
  .select {
    background-color: rgb(171, 164, 240, 0.3);
    border-width: 0px 0px 2px 0px;
    border-color: #572caf;
    padding: 7px 0px 7px 10px;
    transition: 0.3;
    min-width: 310px;
    width: 100%;
    margin: 10px 0px;
    margin-right: 0px;
    font-family: "Medium";
    font-weight: bold;
    color: #572caf;
    font-size: 15px;
  }
  .select-large {
    background-color: rgb(171, 164, 240, 0.3);
    border-width: 0px 0px 2px 0px;
    border-color: #572caf;
    padding: 7px 0px 7px 10px;
    transition: 0.3;
    width: 310px;
    min-width: 310px;
    margin: 10px 0px;
    margin-right: 0px;
    font-family: "Medium";
    font-weight: bold;
    color: #572caf;
    font-size: 15px;
  }
}
