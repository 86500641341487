.vert-wrapper {
  display: flex;
  flex-direction: column;
}
.horiz-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.hidden {
  display: none;
  background-color: red;
}
.over {
  overflow: scroll;
  height: 210px;
}

@media screen and (max-width: 920px) {
  .horiz-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    flex-wrap: nowrap;
  }
  .over {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
