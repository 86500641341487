.vert-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.horizontal-wrapp {
  display: flex;
}
.hidden {
  display: none;
  background-color: red;
}
@media screen and (max-width: 920px) {
  .vert-wrapper {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: auto;
  }
  .horizontal-wrapp {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: auto;
  }
}
