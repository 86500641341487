.vert-wrapper {
  display: flex;
  flex-direction: column;
}
.ho-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.hidden {
  display: none;
  background-color: red;
}
.over {
  overflow: scroll;
  max-height: 165px;
}

@media screen and (max-width: 920px) {
  .ho-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
