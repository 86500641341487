.profil {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.wrapper-profil {
  background-color: white;
  width: 225px;
  border-radius: 10px;
  padding: 30px;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
}
.little-icon {
  width: 100px;
}
.name {
  text-align: start;
}
.bottom {
  margin-top: auto;
}
.icons {
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  text-align: end;
}
.link-psy {
  padding: 12px 25px;
  border-radius: 6px;
  background-color: #ffab23;
  color: white;
  border: none;
  box-shadow: none;
  border-color: transparent;
  transition: 0.3s;
  font-family: "Medium";
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
}
.link-psy:hover {
  font-family: "Medium";
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  padding: 12px 25px;
  border-radius: 6px;
  background-color: #572caf;
  color: white;
  border: none;
  box-shadow: none;
  border-color: transparent;
}

@media screen and (max-width: 920px) {
  .wrapper-profil {
    background-color: white;
    width: 225px;
    border-radius: 10px;
    padding: 30px;
    text-decoration: none;
    color: black;
    margin: 10px 0px;
    text-align: start;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
