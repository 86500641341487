.wrapper-tag {
  background-color: rgb(171, 164, 240, 0.3);
  border: 2px #572caf solid;
  padding: 10px 15px;
  width: fit-content;
  border-collapse: separate;
  border-radius: 10px;
  margin: 5px 5px 5px 0px;
  text-align: left;
}

.wrapper-tag-remote {
  background-color: rgba(255, 171, 35, 0.3);
  border: 2px #ffab23 solid;
  padding: 10px 10px;
  width: fit-content;
  border-collapse: separate;
  border-radius: 10px;
  margin: 5px 5px 5px 0px;
}

.tag {
  margin: 0px 0px;
}
