.button {
  padding: 12px 25px;
  border-radius: 6px;
  background-color: #ffab23;
  color: white;
  border: none;
  box-shadow: none;
  border-color: transparent;
  transition: 0.3s;
  font-family: "Medium";
  font-weight: bold;
  font-size: 15px;
}

.button:hover {
  font-family: "Medium";
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  padding: 12px 25px;
  border-radius: 6px;
  background-color: #ffab23;
  color: white;
  border: none;
  box-shadow: none;
  border-color: transparent;
}
