.nav {
  font-family: "Medium";
  padding: 25px 0px;
  margin-bottom: 100px;
  width: 100vw;
  text-align: center;
  position: static;
  top: 0;
  z-index: 10;
}

.nav-logo {
  width: 90px;
  height: auto;
}
@media screen and (max-width: 900px) {
  .nav {
    font-family: "Medium";
    padding: 25px 0px;
    margin-bottom: 100px;
    width: 100vw;
    text-align: center;
    position: static;
    top: 0;
    z-index: 10;
  }
  .nav-logo {
    width: 70px;
    height: auto;
  }
}
