.vert-wrapper {
  display: flex;
  flex-direction: column;
}
.hor-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.hidden {
  display: none;
  background-color: red;
}
.other {
  color: #572caf;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 920px) {
  .hor-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
  }
}
