.vert-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.horiz-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.hidden {
  display: none;
  background-color: red;
}
.over {
  overflow: scroll;
  height: 220px;
  margin-bottom: 20px;
}

@media screen and (max-width: 920px) {
  .horiz-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }
  .over {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
