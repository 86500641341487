.App {
  font-family: "Medium";
  background-color: #f5ece5;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("MontserratMedium"),
    url("./public/fonts/Montserrat-Medium.ttf") format("truetype");
}
