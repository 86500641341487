.wrapperformu {
  display: flex;
  flex-direction: row;
  max-width: 920px;
  border-radius: 10px;
  box-shadow: 0px 0px black;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
.img-form {
  width: 300px;
  height: auto;
  margin: 20px auto 20px auto;
  border-radius: 10px;
}
.wrapp-leftimg {
  width: 332px;
  min-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}
.formwrapper {
  width: 588px;
}
@media screen and (max-width: 920px) {
  .wrapperformu {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 0px black;
    background-color: #ffffff;
    text-align: center;
  }
  .img-form {
    display: none;
  }
  .formwrapper {
    width: 100%;
  }
}
