.wrapper-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Medium";
  text-align: end;
  width: 100%;
  margin-top: 20px;
}
.right-link {
  align-self: center;
}
