.vertical-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 25px 18px 25px 18px;
}
.button {
  padding: 12px 25px;
  border-radius: 6px;
  background-color: #ffab23;
  color: white;
  border: none;
  box-shadow: none;
  border-color: transparent;
  transition: 0.3s;
}
.red {
  color: red;
}
@media screen and (max-width: 920px) {
  .vertical-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 15px 5px 5px 15px;
    text-align: center;
  }
}
