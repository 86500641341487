.home-wrapper {
  background-color: white;
  width: 600px;
  margin: 0px auto;
  border-radius: 10px;
  padding: 30px;
}
.icon-link {
  width: 30px;
  height: 30px;
}
.link-hor-wrapp {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.type-link {
  background-color: rgb(171, 164, 240, 0.3);
  padding: 20px 20px 5px 20px;
  border-radius: 10px;
  cursor: pointer;
}
@media screen and (max-width: 920px) {
  .link-hor-wrapp {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .home-wrapper {
    background-color: white;
    width: auto;
    margin: 0px auto;
    border-radius: 10px;
    padding: 30px;
  }
}
